import VueRouter from 'vue-router'
import PageHome from '../pages/Home'

const Routes = [
    {path: '/', name: 'home', component: PageHome },
    {path: '/cartoons', name: 'cartoons', component: () => import('../pages/Cartoons')},
    {path: '/about', name: 'about', component: () => import('../pages/About')},
    {path: '/game/memory', name: 'memory', component: () => import('../pages/Memory')}
  ];

const router = new VueRouter({
    mode: 'history',
    routes: Routes
})

function GetRouter() {
    return router;
}


router.beforeEach((to, from, next) => {
    next();
})

export {
    Routes,
    GetRouter
}
