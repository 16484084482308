<template>
<div class="monkey-layers"  :style="style">
    <img :src="require('@/assets/CircleMonkey-Monkey.png')" />
    <img :src="require('@/assets/CircleMonkey-Eyes-Open.png')" v-if="!blink"/>
    <img :src="require('@/assets/CircleMonkey-Eyes-Closed.png')" v-if="blink"/>
    <img :src="require('@/assets/CircleMonkey-Mouth-Closed.png')" v-if="mouth == 'v'"/>
    <img :src="require('@/assets/CircleMonkey-Mouth-Open.png')" v-if="mouth == 'o' || mouth == '<' "/>
    <img :src="require('@/assets/CircleMonkey-SoundLines.png')" v-if="mouth == '<' "/>
</div>
</template>

<script>
export default {
    name: "CircleMonkeyAnimation",
    mounted() {
        this.animationTimer = window.setInterval(this.animate, 100);
        this.audioCircleCircle = new Audio(require('@/assets/circlecircle.mp3'))
        this.audioMonkeyMonkey = new Audio(require('@/assets/monkeymonkey.mp3'))
        this.audioCircleMonkey = new Audio(require('@/assets/circlemonkey.mp3'))
    },
    props: {
        question: { type: String, default: '' },
        size: { type: [Number, String], default: "128"}
    },
    data() {
        return {
            audioCircleCircle: null,
            audioMonkeyMonkey: null,
            audioCircleMonkey: null,
            animationTimer: null,
            blink: false,
            localValue: "",
            animationIndex: 0,
            animationFactor: 0,
        }
    },
    watch: {
        question(newValue) {
            console.log("New Question", newValue)
            if (newValue == "") {
                this.localValue = "";
            } else if (newValue.toLowerCase().indexOf("z") >= 0) {
                this.localValue = "v<vov";
                this.audioCircleCircle.play();
                console.log('play', this.audioCircleCircle)
            } else if (newValue.toLowerCase().indexOf("y") >= 0) {
                this.localValue = "v<vov";
                this.audioCircleMonkey.play();
            } else {
                this.localValue = "v<vov";
                this.audioMonkeyMonkey.play();
            }
            this.animationIndex = 0;
            console.log("new animation", this.localValue)
        }
    },
    methods: {
        animate() {
            this.animationFactor++;
            if (this.animationFactor > 1) {
                this.animationFactor = 0;
                this.animationIndex++;
            }
            let changeAnimation = Math.random() > (this.blink ? 0.3 : 0.95);
            if (changeAnimation) this.blink = !this.blink;
        }

    },
    computed: {
        style() {
            return 'width: ' + this.size + 'px; height: ' + this.size + 'px';
        },
        mouth() {
            if (this.animationIndex >= this.localValue.length) {
                return 'v';
            } else {
                return this.localValue[this.animationIndex];
            }
        }
    }
}
</script>

<style>
:root {
    --v-monkey-size: 100%;
}
</style>

<style scoped>
.monkey-layers {
    position: relative;
    display: inline-block;
    width: var(--v-monkey-size);
    height: var(--v-monkey-size);
}

.monkey-layers img {
    position: absolute;
    width: var(--v-monkey-size);
    height: var(--v-monkey-size);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

</style>
