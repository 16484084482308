import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import padStart from 'lodash/padStart';
Vue.config.productionTip = false

import CircleMonkeyAnimation from "@/components/CircleMonkeyAnimation";
import SquarePandaAnimation from "@/components/SquarePandaAnimation";
import TriangleSharkAnimation from "@/components/TriangleSharkAnimation.vue";
Vue.component('CircleMonkeyAnimation', CircleMonkeyAnimation);
Vue.component('SquarePandaAnimation', SquarePandaAnimation);
Vue.component('TriangleSharkAnimation', TriangleSharkAnimation);

import {GetRouter} from "@/plugins/router";
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer);
Vue.filter('asArcadeScore', function(value) {
  return padStart(value, 3, '0') + '0';
})
const router = GetRouter();

new Vue({
  vuetify,
  router: router,
  render: h => h(App)
}).$mount('#app')
