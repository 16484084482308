<template>
  <v-container>
    <v-row>
      <v-col offset-md="3" md="6" xs="12" class="text-center ink-inc headline">
        Welcome to the home of Ink inc. This is where the Square Panda comics and books
        get made. See our
        <router-link :to="{ name: 'cartoons' }">latest comic</router-link>.
      </v-col>
    </v-row>
    <v-row>
      <v-col offset-md="1" md="3" xs="12" class="text-center">
        <SquarePandaAnimation
          :question="pandaQuestion"
          :size="characterSize"
          @click="pandaClick"
          class="breathing-panda"
        />
        <div class="ink-inc mt-n4">Square Panda</div>
      </v-col>
      <v-col md="4" xs="12" class="text-center">
        <CircleMonkeyAnimation
          :question="monkeyQuestion"
          :size="characterSize"
          class="breathing-monkey"
        />
        <div class="ink-inc mt-n4">Circle Monkey</div>
      </v-col>
      <v-col md="3" xs="12" class="text-center">
        <TriangleSharkAnimation
          :question="sharkQuestion"
          :size="characterSize"
          @click="sharkClick"
          class="breathing-shark"
        />
        <div class="ink-inc mt-n4">Triangle Shark</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" offset-md="4">
        <v-text-field
          v-model="question"
          hint="Don't make it too tough, he has a VERY limited vocabulary"
          label="Ask a Circle Monkey a question"
          @keydown.enter="ask"
        />
        <v-btn color="black" dark block class="mt-1" @click="ask">Ask</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      drawer: false,
      question: "",
      monkeyQuestion: "",
      pandaQuestion: "",
      sharkQuestion: "",
    };
  },
  methods: {
    pandaClick() {
      if (this.pandaQuestion.length > 4) {
        this.pandaQuestion = "";
      }
      this.pandaQuestion += "o";
    },
    sharkClick() {
      if (this.sharkQuestion.length > 4) {
        this.sharkQuestion = "";
      }
      this.sharkQuestion += "o";
    },
    ask() {
      this.monkeyQuestion = this.question;
      this.question = "";
    },
  },
  computed: {
    characterSize() {
      return "200";
    },
  },
};
</script>

<style>
:root {
  --breathing-min-scale: 0.98;
}

@keyframes breathing {
  0% {
    transform: scale(var(--breathing-min-scale));
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(var(--breathing-min-scale));
  }

  100% {
    transform: scale(var(--breathing-min-scale));
  }
}

.breathing-panda {
  animation: breathing 5s ease-out infinite normal;
}
.breathing-monkey {
  animation: breathing 3.2s ease-out infinite normal;
}
.breathing-shark {
  animation: breathing 9.5s ease-out infinite normal;
}
</style>
