<template>
    <v-navigation-drawer v-model="localDrawer" absolute bottom temporary>
        <v-list>
            <v-list-item v-for="item in items" :key="item.value">
                <v-list-item-title>
                    <router-link v-if="item.value"
                        :to="{ name: item.value }"
                        class="black--text text-decoration-none headline ink-inc">
                        {{ item.title }}
                    </router-link>
                  <a v-else target="_platdrawer"
                               :href="item.href"
                               class="black--text text-decoration-none headline ink-inc">
                    {{ item.title }}
                  </a>
                </v-list-item-title
                >
            </v-list-item>
            <v-list-item>
                <v-divider />
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="grey--text body-1 font-weight-light">{{ version }}</v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: "NavDrawer",
    data() {
        return {
            localDrawer: false,
            items: [
                {title: "Home", value: "home"},
                {title: "Cartoons", value: "cartoons"},
                {title: "Memory Game", value: "memory"},
                {title: "Plat Drawer", href: "/PlatDrawer/index.html"},
                {title: "About Us", value: "about"},
            ],
        };
    },
    watch: {
        drawer: {
            immediate: true,
            handler(newValue) {
                this.localDrawer = newValue;
            },
        },
        localDrawer(newValue) {
            this.$emit("update:drawer", newValue);
        },
    },
    props: {
        drawer: {type: Boolean, default: false},
    },
    computed: {
        version() {
            return 'V' + process.env.VUE_APP_VERSION + ' - #' + process.env.VUE_APP_GIT_HASH;
        }
    }
};
</script>
