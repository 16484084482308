<template>
    <v-app :class="{'sm': $vuetify.breakpoint.smAndDown}">
        <v-app-bar app color="white" light>
            <v-app-bar-nav-icon
                    variant="text"
                    @click.stop="drawer = !drawer"
                    icon="mdi-cancel"
            ></v-app-bar-nav-icon>

            <router-link :to="{ name: 'home' }" active-class="text-decoration-none black--text">
                <div class="d-flex align-center">
                    <v-img
                            alt="Inc Ink."
                            class="shrink mr-2"
                            contain
                            :src="require('@/assets/InkInc.png')"
                            transition="scale-transition"
                            width="60"
                    />
                    <div class="display-1 ink-inc">Ink Inc.</div>
                </div>
            </router-link>
        </v-app-bar>

        <nav-drawer :drawer.sync="drawer"/>

        <v-main class="fill-height">
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer.vue";

export default {
    name: "App",
    components: {
        NavDrawer,
    },
    data() {
        return {
            drawer: false,
        };
    },
};
</script>
