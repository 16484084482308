<template>
<div class="panda-layers" :style="style">
    <img @click="clicked" :src="require('@/assets/SquarePanda-Panda.png')" />
    <img @click="clicked" :src="require('@/assets/SquarePanda-Eyes-Open.png')" v-if="!blink"/>
    <img @click="clicked" :src="require('@/assets/SquarePanda-Eyes-Closed.png')" v-if="blink"/>
    <img @click="clicked" :src="require('@/assets/CircleMonkey-Mouth-Closed.png')" v-if="mouth == 'v'"/>
    <img @click="clicked" :src="require('@/assets/CircleMonkey-Mouth-Open.png')" v-if="mouth == 'o' || mouth == '<' "/>
    <img @click="clicked" :src="require('@/assets/CircleMonkey-SoundLines.png')" v-if="mouth == '<' "/>
</div>
</template>

<script>
export default {
    name: "SquarePandaAnimation",
    mounted() {
        this.animationTimer = window.setInterval(this.animate, 100);
    },
    props: {
        question: { type: String, default: '' },
        size: { type: [Number, String], default: "128"}
    },
    data() {
        return {
            animationTimer: null,
            blink: false,
            localValue: "",
            animationIndex: 0,
            animationFactor: 0,
        }
    },
    watch: {
        question(newValue) {
            console.log("New Question", newValue)
                this.localValue = newValue;
            this.animationIndex = 0;
            console.log("new animation", this.localValue)
        }
    },
    methods: {
        clicked() {
            this.$emit('click');
            console.log("clicked")
        },
        animate() {
            this.animationFactor++;
            if (this.animationFactor > 3) {
                this.animationFactor = 0;
                this.animationIndex++;
            }
            let changeAnimation = Math.random() > (this.blink ? 0.3 : 0.95);
            if (changeAnimation) this.blink = !this.blink;
        }

    },
    computed: {
        style() {
            return 'width: ' + this.size + 'px; height: ' + this.size + 'px';
        },
        mouth() {
            if (this.animationIndex >= this.localValue.length) {
                return 'v';
            } else {
                return this.localValue[this.animationIndex];
            }
        }
    }
}
</script>

<style>
:root {
    --v-panda-size: 100%;
}
</style>

<style scoped>
.panda-layers {
    position: relative;
    display: inline-block;
    width: var(--v-panda-size);
    height: var(--v-panda-size);
}

.panda-layers img {
    position: absolute;
    width: var(--v-panda-size);
    height: var(--v-panda-size);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

</style>
